<template>
  <v-app>
    <v-banner
        single-line
        height="500"
        class="children-banner"
    >
      <v-carousel height="100%"
                  class="carousel-new carousel-banner"
                  hide-delimiter-background
                  show-arrows-on-hover
                  :reverse="false"
                  :cycle="true"
                  :continuous="true">
        <v-carousel-item v-for="(banner,index) in bannerList" :key="index">
          <v-img
              :src="banner.img"
              height="100%"
          >
            <template v-slot:placeholder>
              <v-row
                  class="fill-height ma-0"
                  align="center"
                  justify="center"
              >
                <v-progress-circular
                    indeterminate
                    size="80"
                    width="8"
                    color="grey lighten-3"
                ></v-progress-circular>
              </v-row>
            </template>
            <v-container color="transparent" class="pa-0 max-width-1200 height-per-100 d-flex align-center">
              <v-card color="transparent" class="ml-0" flat>
                <v-card-title class="pa-0 font-size-banner-title family-Bold" :style="'color: ' + banner.titleColor + '!important;'">
                  <div>
                    <div v-for="(title,tipIndex) in banner.title" :key="tipIndex" class="pt-2" style="font-weight: 500">{{title}}</div>
                  </div>
                </v-card-title>
                <v-card-title class="pa-0 font-size-banner-tip mt-8" :style="'color: ' + banner.titleColor + '!important;'">
                  <div>
                    <div v-for="(tip,tipIndex) in banner.tip" :key="tipIndex" class="mb-3">{{tip}}</div>
                  </div>
                </v-card-title>
                <v-card-actions class="pa-0 mt-10" v-if="banner.btn">
                  <v-btn
                      rounded
                      width="160"
                      height="50"
                      class="font-size-20"
                      @click.stop="handleBannerBtn(banner.router)"
                  >
                    <span>{{ banner.btn }}</span>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-container>
          </v-img>
        </v-carousel-item>
      </v-carousel>
    </v-banner>
    <!-- 万目云营销闭环 -->
    <div class="d-flex justify-center pb-5">
      <v-card flat class="max-width-1200">
        <div class="public-title">
          <p>{{ data1.wrap1.titleEng }}</p>
          <div>
            <span>{{ data1.wrap1.title }}</span>
            <span>{{ data1.wrap1.tip }}</span>
          </div>
        </div>
        <v-container class="d-flex justify-center">
          <img :src="data1.wrap1.img" style="height:400px;"  />

        </v-container>
      </v-card>
    </div>
    <!-- 趣味内容+丰富玩法一站式营销服务 -->
    <div class="d-flex justify-center" style="padding-bottom: 70px;background-color: #F5F9FD;">
      <v-card flat class="max-width-1200" color="transparent">
        <div class="public-title">
          <p>{{ data1.wrap2.titleEng }}</p>
          <div>
            <span>{{ data1.wrap2.title }}</span>
          </div>
        </div>
        <v-tabs v-model="tab1" centered background-color="transparent" class="g-tabs font-size-20">
          <v-tab v-for="(v, k) in data1.wrap2.list" :key="k">
            <b>{{ v.tabTitle }}</b>
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab1" class="mt-12 " style="width:720px; margin:0 auto; background-color: transparent">
          <v-tab-item v-for="(v, k) in data1.wrap2.list" :key="k"
                      :transition="false">
            <div class="d-flex justify-center">
              <transition name="slide-fade" appear>
                <v-img style="background-color: transparent"
                       max-width="400" max-height="260"
                       contain
                       v-show="tab1 === k"
                       :src="v.img">
                  <template v-slot:placeholder>
                    <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                    >
                      <v-progress-circular
                          indeterminate
                          size="80"
                          width="8"
                          color="grey lighten-3"
                      ></v-progress-circular>
                    </v-row>
                  </template>
                </v-img>
              </transition>
              <transition name="slide-fade-reverse" appear>
                <div style="margin-left: 80px;"
                     class="d-flex align-center"
                     v-show="tab1 === k">
                  <div>
                    <v-card-title class="pa-0 font-size-24 font-weight-bold white-space-pre-wrap" style="line-height: 1.2">{{v.childrenTitle}}</v-card-title>
                    <v-card-text class="pa-0 mt-4 font-size-16 color-666">
                      <p class="mb-3" v-for="(i, j) in v.children" :key="j">{{i}}</p>
                    </v-card-text>
                  </div>
                </div>
              </transition>
            </div>
          </v-tab-item>
        </v-tabs-items>
      </v-card>
    </div>
    <!--<div class="d-flex justify-center home-case">-->
    <!--  <v-card class="max-width-1200" flat>-->
    <!--    <div class="public-title" style="margin-top: 60px;">-->
    <!--      <p>{{ data1.wrap3.titleEng }}</p>-->
    <!--      <div>-->
    <!--        <span>{{ data1.wrap3.title }}</span>-->
    <!--        <span>{{ data1.wrap3.tip }}</span>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <v-tabs v-model="tab2"-->
    <!--            centered-->
    <!--            background-color="transparent"-->
    <!--            class="mt-5 g-tabs">-->
    <!--      <v-tab v-for="(v, k) in data1.wrap3.homeCase" :key="k">-->
    <!--        <b>{{ v.title }}</b>-->
    <!--      </v-tab>-->
    <!--    </v-tabs>-->

    <!--    <v-tabs-items v-model="tab2" class="new-tabs-items-two mt-10" >-->
    <!--      <v-tab-item-->
    <!--          v-for="(v, k) in data1.wrap3.homeCase"-->
    <!--          :key="k"-->
    <!--          :transition="false"-->
    <!--      >-->
    <!--        <transition name="fade">-->
    <!--          <div v-show="tab2 === k" class="width-per-100 d-flex justify-center px-2">-->
    <!--            <v-card-->
    <!--                v-for="(info,infoIndex) in v.tabItem" :key="infoIndex"-->
    <!--                :class="['item-card',(v.tabItem.length-1) != infoIndex?'mr-10':'']"-->
    <!--                width="240"-->
    <!--                height="410"-->
    <!--                @click="goCaseDetail(info)"-->
    <!--            >-->
    <!--              <v-img-->
    <!--                  max-height="340"-->
    <!--                  class="tran-sec"-->
    <!--                  :src="info.coverImg"-->
    <!--                  :aspect-ratio="9/16"-->
    <!--              >-->
    <!--              </v-img>-->
    <!--              <div class="text-box">-->
    <!--                <v-card-text class="title" v-text="info.itemTitle"></v-card-text>-->
    <!--              </div>-->
    <!--            </v-card>-->
    <!--          </div>-->
    <!--        </transition>-->
    <!--      </v-tab-item>-->
    <!--    </v-tabs-items>-->
    <!--  </v-card>-->
    <!--</div>-->
    <!--<div class="d-flex justify-center pb-5">-->
    <!--  <v-card class="max-width-1200" width="100%" :flat="true" color="transparent">-->
    <!--    <div class="public-title" style="margin-top: 60px;">-->
    <!--      <p>PARTMER</p>-->
    <!--      <div>-->
    <!--        <span>合作伙伴</span>-->
    <!--      </div>-->
    <!--    </div>-->
    <!--    <v-img class="mt-n5" contain src="https://h5.ophyer.cn/official_website/other/home-hzhb3.png"/>-->
    <!--  </v-card>-->
    <!--</div>-->
    <transition name="fade" mode="out-in">
      <div class="dialog" v-show="showDialog" @click.stop>
        <div class="">
          <div class="close-btn" @click.stop="showDialog = false;">
            <v-img class="icon" src="../../../public/img/icons/menu/close.png"
                   max-width="12" height="12" contain></v-img>
          </div>
          <div class="d-flex justify-center font-size-24 pa-0 mt-6">我要代理</div>
          <div class="d-flex justify-center mt-10">
            <div>
              <v-img src="@/assets/login/wangWexin.png" width="200" height="200" contin></v-img>
              <v-card-text class="text-align-center font-size-14 pa-0 mt-3 color-666">扫一扫加我微信</v-card-text>
            </div>
            <div class="ml-6 mt-2">
              <v-row class="pa-0 align-center">
                <v-img max-width="46" height="46" contain src="../../../public/img/icons/menu/contacts.png"></v-img>
                <v-card flat class="ml-3 mt-1">
                  <v-card-title class="font-size-18 pa-0">联系人</v-card-title>
                  <v-card-text class="font-size-14 color-999 pa-0 mt-1">汪总</v-card-text>
                </v-card>
              </v-row>
              <v-row class="pa-0 mt-7">
                <v-img max-width="46" height="46" contain src="../../../public/img/icons/menu/contact-phone.png"></v-img>
                <v-card flat class="ml-3 mt-1">
                  <v-card-title class="font-size-18 pa-0">联系电话</v-card-title>
                  <v-card-text class="font-size-14 color-999 pa-0 mt-1">18601279119</v-card-text>
                </v-card>
              </v-row>
              <v-row class="pa-0 mt-7">
                <v-img max-width="46" height="46" contain src="../../../public/img/icons/menu/contact-email.png"></v-img>
                <v-card flat class="ml-3 mt-1">
                  <v-card-title class="font-size-18 pa-0">邮箱</v-card-title>
                  <v-card-text class="font-size-14 color-999 pa-0 mt-1">meng.wang@flowingcloud.com</v-card-text>
                </v-card>
              </v-row>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </v-app>
</template>

<script>
import textData from '@/static/textData.js';
import qs from 'qs'

export default {
  name: 'Home',
  components: {
  },
  data() {
    return {
      data1: textData.WmyHome,
      tab1: null,
      tab2: null,
      bannerList: [
        {
          img: "https://h5.ophyer.cn/official_website/banner/bannerWmy01-1.jpg",
          // title: ["产品数字化+虚拟现实","助力企业开启元宇宙营销新时代"],
          // title: ["产品数字化+虚拟现实","助力企业开启数字营销新时代"],
          title: ["产品数字化+虚拟现实","助力企业开启数字化新时代"],
          tip: ["虚拟、超沉浸体验，重塑品牌影响力"],
          titleColor:"#FFFFFF",
        },
        {
          img: "https://h5.ophyer.cn/official_website/banner/home-banner-h-3.jpg",
          title: ["VR带看全新升级"],
          titleColor:"#FFFFFF",
          // tip: ["互动演示“零”距离，营销更近一步","打破空间和距离，无障碍交流更高效"],
          tip: ["互动演示“零”距离","打破空间和距离，无障碍交流更高效"],
          // btn: "查看详情",
          // router: "/serviceProducts/VRSceneShow"
        },
        // {
        //   img: "https://h5.ophyer.cn/official_website/banner/bannerWmy03-1-1.jpg",
        //   title: ["海量IP资源","赋能品牌商业价值"],
        //   titleColor:"#FFFFFF",
        //   tip: ["明星IP、影视IP、游戏IP、文化IP等，多品类IP加持","持续助力品牌营销，增强品牌影响力"],
        //   btn: "查看详情",
        //   router: "/IP/Home"
        // },
        {
          img: "https://h5.ophyer.cn/official_website/banner/bannerWmy04-1.jpg",
          title: ["低门槛、高收益","诚邀您的加入"],
          tip: ["多重代理收益、培训支持、驻地支持、市场支持","服务支持、大客陪跑"],
          btn: "我要代理"
        }
      ],
      showDialog: false
    }
  },
  created() {
    let _this = this;
    document.addEventListener("click",()=>{
      _this.showDialog = false;
    })
  },
  methods: {
    handleBannerBtn(router) {
      if(router){
        window.open(window.location.protocol + "//" + window.location.host + router);
      }else {
        this.showDialog = true;
      }
    },
    goCaseDetail:(item) =>{
      let query = {
        source: 'qita',
        anli: item.self
      }
      let url = '/CaseDetail?'+qs.stringify(query)
      window.open(url, '_blank')
    }
  }
}
</script>

<style lang="scss" scoped>
.dialog{
  z-index: 3;
  width: 550px;
  height: 380px;
  display: flex;
  justify-content: center;
  border-radius: 10px;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%,-50%);
  background-color: #FFFFFF;
  box-shadow: 0px 0px 15px 5px rgb(0 114 255 / 10%);
  .close-btn{
    .icon {
      cursor: pointer;
      top: 10px;
      left: 480px;
    }
  }
  ::v-deep .v-card--link {
    cursor: unset;
  }
}
//::v-deep .public-title{
//  height: unset;
//  margin-bottom: unset;
//  margin-top: 80px;
//  p {
//    height: 40px;
//    line-height: 40px;
//    margin-bottom: 0;
//  }
//}

::v-deep .home-case {
  .new-tabs-items-two {
    width: 1200px;
    min-height: unset;
    padding-bottom: 70px;
    .v-window__container {
      background: #FFFFFF;
      padding-bottom: 0;
      min-height: unset;
    }
    .v-window-item {
      width: 100%;
      margin: 0;
      display: flex;
      justify-content: space-around;
      .item-card {
        display: inline-block;
      }
    }
  }
}
</style>
